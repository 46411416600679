/* General Swiper adjustments */
html,
body,
#root,
.swiper-container,
.swiper-wrapper,
.swiper-slide {
  height: 100vh;
  margin: 0;
}

.swiper-container {
  min-height: 100vh;
}

.swiper-wrapper,
.slide-content {
  min-height: 100vh;
}

.slide-image {
  width: 100vw;
  min-height: 100vh;
  object-fit: cover; /* Ensures the image scales to cover the slide */
}

/* Slide content */
.slide-content {
  position: relative;
  width: 100%;
  height: 80vh; /* Full viewport height */
}

/* Text inside slide */
.slide-text {
  position: absolute;
  top: 50%; /* Centered vertically */
  left: 50%; /* Centered horizontally */
  transform: translate(-50%, -50%);
  color: white;
  padding: 1rem;
  text-align: center;
  font-size: 4rem;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.7);
  line-height: 1.2;
}
@media (max-width: 768px) {
  .slide-text {
    font-size: 3rem; /* Reduce font size for smaller screens */
    line-height: 1.1; /* Tighter line spacing for mobile view */
    padding: 0.5rem; /* Reduce padding for better alignment */
  }
}

.slide-text-olympic {
  position: absolute;
  top: 20%; /* Centered vertically */
  left: 50%; /* Centered horizontally */
  transform: translate(-50%, -50%);
  color: white;
  padding: 1rem;
  text-align: center;
  font-size: 4rem;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.7);
  line-height: 1.2;
}
@media (max-width: 768px) {
  .slide-text-olympic {
    font-size: 2.5rem; /* Reduce font size for smaller screens */
    line-height: 1.1; /* Tighter line spacing for mobile view */
    padding: 0.5rem; /* Reduce padding for better alignment */
  }
}

.slide-text-gov {
  position: absolute;
  top: 20%; /* Centered vertically */
  left: 5%; /* Centered horizontally */
  color: white;
  padding: 1rem;
  text-align: center;
  font-size: 4rem;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.7);
  line-height: 1.2;
}
@media (max-width: 768px) {
  .slide-text-gov {
    margin-top: 18%;
    right: 5%;
    font-size: 2rem;
    font-weight: bold;
  }
}

.slide-sub-text {
  position: absolute;
  top: 45%; /* Centered vertically */
  left: 5%; /* Centered horizontally */
  color: #ffaa1d;
  padding: 1rem;
  text-align: left;
  font-size: 30px;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.7);
  line-height: 1.2;
  width: 40%;
}
@media (max-width: 768px) {
  .slide-sub-text {
    right: 5%;
    width: 90%;
    font-size: 18px;
    text-shadow: none;
  }
}

.slide-btn {
  position: absolute;
  top: 85%;
  left: 5%; /* Centered horizontally */
  color: #ffaa1d;
  padding: 1rem;
  text-align: left;
  font-size: 35px;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.7);
  line-height: 1.2;
  width: 50%;
}
@media (max-width: 768px) {
  .slide-btn {
    top: 90%;
    left: 3%;
  }
}

.slide-time {
  position: absolute;
  top: 65%; /* Centered vertically */
  left: 50%; /* Centered horizontally */
  transform: translate(-50%, -50%);
  color: white;
  padding: 3rem;
  text-align: center;
  font-size: 4rem;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.7);
  overflow: visible !important;
}

.image-src {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}

.image-src:hover {
  transform: scale(2); /* Enlarges the background */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Adds shadow effect */
  border: 2px solid rgba(255, 255, 255, 0.8); /* Optional: Adds a subtle white border */
}

/* 
.slide-content {
    position: relative;
  }
  
  .slide-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .slide-content::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    z-index: 1;
  }
  
  .slide-text {
    position: relative;
    z-index: 2;
    
    font-size: 4rem; 
    
    text-align: center;
    padding: 1rem 2rem;
  }  */
