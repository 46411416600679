@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

/* App.css */
.App {
  text-align: center;
}

/* ChatWindow.css */
.chat-window {
  width: 500px;
  margin: 0 auto;
  background-color: #f4f4f9;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.message-container {
  height: 400px;
  overflow-y: auto;
  padding: 10px;
}

.message {
  padding: 8px;
  margin: 5px 0;
  border-radius: 4px;
  max-width: 80%;
}

.message.user {
  background-color: #d1e7ff;
  align-self: flex-end;
}

.message.bot {
  background-color: #e2e3e5;
  align-self: flex-start;
}

.input-container {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
}

input {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-left: 10px;
}

button:hover {
  background-color: #0056b3;
}

.bordered {
  font-size: 15px;
  border: 1px solid grey;
  padding: 12px;
  margin: 12px 0;
  border-radius: 5px;
  cursor: pointer;
  font-family: Arial, sans-serif !important;
}
.header_background {
  padding-top: 10px;
  padding-bottom: 10px;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: scroll;
  background-position: center center;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.nav-buttons {
  font-family: "Rubik", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 400 !important;
  font-style: normal;
  color: #ffffff !important;
  text-transform: none !important;
  font-size: 17px !important;
  cursor: pointer;
  text-decoration: none !important;
  /* border-bottom: 2px solid #ffb50a; */
}
.nav-buttons:hover {
  color: #ffb50a !important;
}
.common-font {
  font-family: "Rubik", sans-serif !important;
  font-optical-sizing: auto;
}
.helpertext {
  background-color: #d2eaff !important;
  font-weight: 700 !important;
  margin: 0 !important;
}
.main-content {
  padding: 0px 400px 0 100px;
}

.link-container {
  max-width: 300px;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 720px) {
  .main-content {
    padding: 0px !important;
  }
}

#error-message {
    white-space: pre-line;
}
