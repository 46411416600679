.map-container {
  background-color: rgb(210 234 255);
  width: 70%;
}

.county {
  transition:
    fill 0.3s ease,
    stroke-width 0.3s ease;
  cursor: pointer;
  stroke-width: 1;
  stroke: black;
}

.utahcounty-title {
  padding-top: 14vh;
  text-align: center;
  color: black;
}

.county:hover {
  fill: blue;
  stroke-width: 3;
}

.map-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.legend-container {
  width: max-content;
  background: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.svgmap {
  width: 35%;
  height: 35%;
}

/* Styling for smaller screens */
@media (max-width: 900px) {
  .map-wrapper {
    display: block;
  }
  .legend-container {
    width: auto;
    margin-top: 20px;
  }
  .svgmap {
    width: 100%;
    height: 35%;
  }
}

.legend-container h3 {
  text-align: center;
  margin-bottom: 10px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.legend-color {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 10px;
  display: inline-block;
  border: 1px solid #aaa;
}

.legend-columns {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.legend-column {
  list-style: none;
  padding: 0;
  margin: 0;
}
