/* Ensure images cover the entire screen height and width */
.carousel-image {
    height: 100vh; /* Full viewport height */
    object-fit: cover; /* Ensures the image covers the container without distortion */
  }
  
  /* Responsive captions */
  .carousel-caption {
    top: 50%;
    transform: translateY(-50%);
  }
  
  /* Styling for smaller screens */
  @media (max-width: 768px) {
    .carousel-caption {
      top: 70%; /* Lower captions for smaller screens */
      transform: translateY(-70%);
      font-size: 1.2rem; /* Smaller text on mobile */
    }
  
    .text-6xl {
      font-size: 1.5rem; /* Large text adjusted for mobile */
    }
  
    .text-4xl {
      font-size: 1rem; /* Medium text adjusted for mobile */
    }
  }
  
  /* Styling for tablets */
  @media (min-width: 769px) and (max-width: 1024px) {
    .carousel-caption {
      top: 60%;
      transform: translateY(-60%);
      font-size: 1.5rem;
    }
  
    .text-6xl {
      font-size: 2rem;
    }
  
    .text-4xl {
      font-size: 1.2rem;
    }
  }
  