/* Override the default accordion header styles */
.custom-accordion-header {
    background-color: #0d2969 !important; /* Ensure custom background color is applied */
    color: white !important;              /* Ensure text is white */
    padding: 0rem !important;           /* Add padding */
    width: 100%;
    border-radius: 1rem;
    border: none !important;              /* Remove any border */
  }
  
  /* Style for the active tab */
  .nav-tabs .nav-link.active {
    background-color: #ffc107 !important;
    color: black !important; /* Optional: Change text color for contrast */
    border-radius: 5px; /* Optional: Adds a rounded corner effect */
  }
  .nav-tabs .nav-link:hover{
    background-color: #007bff !important;
    color: white !important; /* Optional: Change text color for contrast */
    border-radius: 5px; /* Optional: Adds a rounded corner effect */
  }
  
  /* Hover state for the Accordion Header */
  .custom-accordion-header:hover {
    background-color: #f0a500 !important; /* Apply hover background color */
    color: white !important;              /* Keep text white on hover */
    padding: 0rem !important;           /* Add padding on hover */
    border-radius: 1rem;
    transition: background-color 0.3s ease; /* Smooth transition for hover */
  }
  
  /* Remove default Bootstrap accordion button styles */
  .accordion-button {
    background-color: transparent !important; /* Remove Bootstrap's default background */
    border: none !important;                  /* Remove border */
    box-shadow: none !important;              /* Remove shadow */
    border-radius: 4rem;
    outline: none !important;                 /* Remove focus outline */
  }
  
  /* To hide the default caret/arrow */
  .accordion-button::after {
    display: none !important;
  }
  
  /* Custom text styling */
  .custom-accordion-header span {
    color: white !important; /* Ensure the text is white */
    font-size: 1.25rem;       /* Adjust the font size if necessary */
  }
  
  /* Remove any unwanted border or outline on hover and focus */
  .accordion-button:focus,
  .custom-accordion-header:focus {
    outline: none !important; /* Ensure there's no outline when focused */
    box-shadow: none !important; /* No shadow */
    border: none !important;     /* Remove borders */
  }
  