/* Default styles */
.responsive-countdown {
    font-size: 1.5rem;
    text-align: center;
    padding: 1rem;
    line-height: 1.6;
  }
  
  .date-text {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  /* .countdown-text {
    font-size: 1.2rem;
  } */
  .countdown-boxes {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 boxes per row */
    gap: 1rem; /* Default gap for larger screens */
    margin: 0 auto;
    padding: 0 1rem; /* Add padding to prevent boxes from overflowing the screen */
  }
  
  @media (max-width: 600px) {
    .countdown-boxes {
      gap: 0.5rem; /* Reduce gap for smaller screens */
      grid-template-columns: repeat(3, 1fr); /* Still 3 boxes per row */
    }
  }
  

 
  
  .countdown-box {
    background-color: rgba(255, 170, 29, 1); /* Semi-transparent black background */
    padding: 0.5rem;
    border-radius: 8px; /* Rounded corners */
    text-align: center;
    color: #454545; /* Text color */
    min-width: 100px; /* Minimum width for uniform size */
   /* Ensure responsive resizing */
    max-width: 130px; /* Limit maximum width */
    flex-wrap: wrap;
  }

  .countdown-number {
    font-size: 2rem; /* Larger font size for numbers */
    font-weight: bold;
  }
  
  .countdown-label {
    font-size: 1rem; /* Smaller font size for labels */
  }
  
 
  
  /* .countdown-box div:first-child {
    font-size: 2rem;
    font-weight: bold;
  }
  
  .countdown-box div:last-child {
    font-size: 1rem;
  } */
  


  .countdown-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem; /* Add space between digit-label pairs */
    flex-wrap: wrap; /* Allow content to wrap on small screens */
  }
  
  .countdown-container div {
    text-align: center;
  }
  
  .countdown-container div:first-child {
    font-size: 2rem; /* Size for digits */
    font-weight: bold;
  }
  
  .countdown-container div:last-child {
    font-size: 1rem; /* Size for labels */
  }
  
  .countdown-text {
    font-size: 1.2rem;
    text-align: center;
    white-space: pre-wrap; /* Allow text to wrap properly */
    word-wrap: break-word;
    overflow-wrap: break-word; /* Ensure long words wrap */
    margin: 0 auto; /* Center the content */
    padding: 0.5rem; /* Add padding for spacing */
  }
  
  /* Adjust styles for small screens */
 
  
  /* Adjust styles for very small screens */
 