/* About Us Section */
.main-container {
  /* max-width: 1200px; */
  /* margin: 100px ; */
  margin-top: 7%;
  padding-top: 8rem;
}

.title {
  font-size: 2.8rem;
  color: var(--primary-color);
  margin-bottom: 30px;
  font-weight: bold;
  text-align: center;
}

.about-us-content {
  font-size: 1.2rem;
  color: var(--text-color);
  line-height: 1.8;
  margin-bottom: 50px;
  text-align: justify;
  max-width: 1000px;
  margin: 0 auto 50px;
}

.icon-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
  gap: 40px;
  margin: 20px auto;
  max-width: 1200px;
}

.icon-box {
  background-color: var(--card-background);
  border-radius: 16px;
  padding: 30px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  width: 280px;
  height: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition:
    transform 0.3s,
    box-shadow 0.3s,
    background-color 0.3s;
}

.icon-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  background-color: var(--hover-bg-color);
}

.icon-box i {
  font-size: 3.5rem;
  color: var(--secondary-color);
  margin-bottom: 20px;
}

.icon-box h3 {
  font-size: 1.4rem;
  color: var(--primary-color);
  font-weight: 600;
  text-align: center;
}

/* CSS Variables */
:root {
  --primary-color: #1b2a49;
  --secondary-color: #f0a500;
  --background-color: #f5f5f5;
  --card-background: #ffffff;
  --text-color: #666;
  --hover-bg-color: #f0f0f0;
}

/* About Us Section */
.map-container {
  /* max-width: 1200px; */
  /* margin: 100px ; */
  margin-top: 100px;
  padding-top: 20px;
}
