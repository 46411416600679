@keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  .bounce-animation {
    display: inline-block;
    animation: bounce 1s infinite;
    
  }
  




  .no-underline {
    text-decoration: none !important;
  }
  


  .navbar-dropdown {
    /* Ensure proper stacking context */
    z-index: 1501 !important; /* Higher than the list container */
  }