/* .geothermal-map {
    height: 90vh;
    width: 100%;
    position: relative;
  } */
  
  .map {
    height: 100%;
    width: 100%;
    z-index: 1; 
  }
  
 


  .geothermal-map {
    display: flex;
    flex-direction: row;
    height: 100vh;
  }
  
  /* .list-container {
    width: 30%;
    padding: 10px;
    background: #d2eaff;
    border-right: 1px solid #ddd;
    overflow-y: auto;
    margin-top: 15vh;
  } */


  .list-container {
    width: 40%;
    padding: 10px;
    background-color: #d2eaff;
    border-right: 1px solid #ddd;
    overflow-y: auto;
    height: calc(100vh - 13vh); /* Adjust for any header height */
    margin-top: 13vh;
  }
  
  .list-container h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  .list-item {
    padding: 10px;
    margin: 5px 0;
    cursor: pointer;
    background-color: #e6f7ff;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .list-item:hover {
    background-color: #cceeff;
  }
  
  .list-item-content {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    font-size: 1rem;
  }
  .list-item-content {
    display: flex;
    justify-content: space-between; /* Ensures spacing between columns in desktop view */
    gap: 10px;
    font-size: 1rem;
    align-items: center; /* Vertically centers items */
  }
  
  .list-item-content span {
    flex: 1; /* Makes each column take equal width */
    text-align: center; /* Align text to the center for better readability */
  }
  
  /* Media Query for Mobile View */
  @media (max-width: 768px) {
    .list-item-content {
      flex-direction: column; /* Stacks items vertically */
      gap: 5px;
      font-size: 0.9rem;
      align-items: flex-start; /* Align items to the start for a clean stacked view */
    }
  
    .list-item-content span {
      flex: unset; /* Resets the equal width behavior for mobile */
      text-align: left; /* Align text to the left for better readability in stacked view */
    }
  }
  
  
  .map-container {
    width: 70%;
  }
  
  .map {
    height: calc(100vh - 13vh);
    width: 100%;
  }
  
  /* Media Query for Mobile View */
  @media (max-width: 768px) {
    .geothermal-map {
      flex-direction: column;
    }
  
    /* .list-container {
      width: 100%;
      height: 40vh;
      margin-top: 15vh;
    } */
    .map {
      height: calc(100vh - 13vh);
      width: 100%;
    }


    .list-container {
      position: fixed;
      top: 0vh; /* Adjust for header height */
      left: 0;
      width: 100%; /* Full width for mobile */
      height: 35%; /* Adjust height as needed */
   z-index: 4;/* Ensure it stays above the map */
      overflow-y: auto;
    }

  
  
    .map-container {
      width: 100%;
      /* height: 45vh; */
      height: 50vh;
      /* margin-top: 10vh; */
    }
  
    .list-item-content {
      flex-direction: column;
      gap: 5px;
      font-size: 0.9rem;
    }
  }
  