/* .icon-box {
    width: 200px;
    height: 200px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
    margin: 10px;
    transition: transform 0.3s ease;
  }
  
  .icon-box::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit; 
    background-size: cover;
    background-position: center;
    transition: transform 0.3s ease; 
    transform: scale(1); 
    z-index: -1;
    filter: brightness(0.8); 
  }
  
  .icon-box:hover::before {
    transform: scale(1.1); 
  }
   */

/* Hide images for accessibility */
/* .hidden-img {
    display: none;
  }
  
  .icon-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
    padding: 0;
    margin: 0 auto;
    max-width: 1200px;
  }
  
  @media (max-width: 768px) {
    .icon-container {
      flex-direction: column;
      align-items: center;
    }
  
    .icon-box {
      width: 80%;
      margin: 0.75rem 0;
    }
  }
  
  .icon-box {
    width: 300px;
    height: 300px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
    margin: 10px;
    transition: transform 0.3s ease;
  }
  
  .icon-box::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    background-size: cover;
    background-position: center;
    transition: transform 0.3s ease;
    transform: scale(1);
    z-index: -1;
  }
  
  .icon-box::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: background-color 0.3s ease;
    z-index: 0;
  }
  
  .icon-box:hover::before {
    transform: scale(1.5);
  }
  
  .icon-box i,
  .icon-box h3 {
    position: relative;
    z-index: 1;
  }
  
  .icon-container > .icon-box:nth-last-child(2),
  .icon-container > .icon-box:nth-last-child(1) {
    margin: 0 auto;
  }
   */

/* 


   .hidden-img {
    display: none;
  }
  
  .icon-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
    padding: 0;
    margin: 0 auto;
    max-width: 1200px;
  }
  
  @media (max-width: 768px) {
    .icon-container {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    .icon-box {
      width: 80%;
      margin: 0.75rem 0;
    }
  }
  
  .icon-box {
    width: 95%;
  
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
    margin: 10px;
    transition: transform 0.3s ease;
  }
  
  .icon-box::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    background-size: cover;
    background-position: center;
    transition: transform 0.3s ease;
    transform: scale(1);
    z-index: -1;
  }
  
  .icon-box::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: background-color 0.3s ease;
    z-index: 0;
  }
  
  .icon-box:hover::before {
    transform: scale(1.5);
  }
  
  .icon-box i,
  .icon-box h3 {
    position: relative;
    z-index: 1;
  }
  
  .icon-container > .icon-box:nth-last-child(2),
  .icon-container > .icon-box:nth-last-child(1) {
    margin: 0 auto;
  }
   */

.hidden-img {
  display: none;
}

.icon-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  padding: 0;
  margin: 0 auto;
  max-width: 1200px;
  box-sizing: border-box;
}

.icon-box {
  flex-basis: calc(33.33% - 1.5rem); /* Three cards per row with gaps */
  max-width: 300px;
  height: 300px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
  margin: 20px 0 20px 20px;
  transition: transform 0.3s ease;
  box-sizing: border-box;
}

.icon-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: inherit;
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease;
  transform: scale(1);
  z-index: -1;
}

.icon-box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease;
  z-index: 0;
}

.icon-box:hover::before {
  transform: scale(1.5);
}

.icon-box i,
.icon-box h3 {
  position: relative;
  z-index: 1;
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .icon-container {
    flex-direction: column;
    align-items: center;
  }

  .icon-box {
    /* width: 90%; */
    margin: 0.75rem 0;
  }
}

.image-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh; /* Full viewport height */
}

@media (max-width: 768px) {
  .image-container {
    margin-top: 23% !important;
    height: 40vh; /* Full viewport height */
  }
}
