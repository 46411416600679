.utah-map-container {
    position: relative;
    width: 99.2vw; /* Full width */
    height: 100vh; /* Full height */
    overflow: hidden; /* Ensure no overflow outside the viewport */
    margin: 0; /* Remove any default margins */
  }
  
  .utah-map-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover; /* Ensures the image covers the entire area without distortion */
  }
  
  .population-overlay {
    position: absolute;
    top: 27%; /* Center vertically */
    left: 70%; /* Center horizontally */
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
    color: white;
    text-align: center;
    padding: 1.5rem 2rem;
    border-radius: 8px; /* Rounded corners for overlay */
    font-family: Arial, sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    width: 20%; /* Default width */
  }
  
  /* Styling for headings and text */
  .population-overlay h2 {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
  }
  
  .population-overlay p {
    font-size: 1.4rem;
    margin: 0;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .population-overlay {
      width: 70%;
      padding: 1rem 1.5rem;
    }
  
    .population-overlay h2 {
      font-size: 1.5rem;
    }
  
    .population-overlay p {
      font-size: 1.2rem;
    }
  }
  
  @media (max-width: 768px) {
    .population-overlay {
      width: 50%;
      padding: 0.8rem 1rem;
    }
  
    .population-overlay h2 {
      font-size: 1.3rem;
    }
  
    .population-overlay p {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .population-overlay {
      width: 50%;
      padding: 0.6rem 0.8rem;
    }
  
    .population-overlay h2 {
      font-size: 1.2rem;
    }
  
    .population-overlay p {
      font-size: 0.9rem;
    }
  }
  