/* .social-media-section {
    padding: 2rem;
    background-color: #f9f9f9;
  }
  
  .social-card {
    background-color: #ffffff;
    border: 1px solid #ddd;
  }
  
  .social-card h3 {
    font-size: 1.5rem;
  }
  
  .social-card a {
    color: #0073e6;
  }
  
  .social-card iframe,
  .social-card blockquote {
    width: 100%;
    height: auto;
  }
   */







   .social-media-section {
    padding: 2rem;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column; /* Stack heading and cards vertically */
    align-items: center; /* Center align the heading */
    gap: 1.5rem; /* Add space between heading and cards */
  }
  
  /* .social-card {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 450px; 
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
  } */


  .social-card {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 450px; /* Ensure consistent width */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Align content properly */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .social-card:hover {
    transform: scale(1.05); /* Slightly increase the size */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
  }
  
  .social-card .content {
    flex: 1;
    overflow: hidden; /* Prevent overflow */
    display: flex;
    /* Center content vertically */
    justify-content: center; /* Center content horizontally */
     padding: 2rem; /* Add minimal padding to prevent excessive spacing */
  margin-top: 0; 
  
  }
  
  .social-card iframe,
  .social-card blockquote {
    width: 100%;
    max-height: 600px; /* Adjust max height for embedded content */
    overflow: hidden;
  }
  
  .social-card-footer {
    padding: 0.5rem;
    text-align: center;
    background-color: #f0f0f0;
    border-top: 1px solid #ddd;
    font-size: 0.9rem;
    color: white;
  }
  