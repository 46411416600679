/* 🌎 Background Styling */
.utah-county-bg {
  background-image: url("/public/img/utahcounty.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 5vh 2vw;
  width: 100%;
}

/* 📌 Main Container */
.utah-county-container {
  width: 90%;
  max-width: 1200px;
  text-align: center;
}

/* 🏠 Title */
.utah-county-title {
  padding-top: 14vh;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 20px;
}

/* 🔳 Desktop Layout (Left Info Boxes, Right Map) */
.utah-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

/* 🛠️ Info Section (Left) */
.utah-info {
  flex: 1;
}

/* 🛠️ Info Grid (Three Rows) */
.utah-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

/* 🗺️ Map Section (Right) */
.utah-map {
  flex: 1;
  display: flex;
  justify-content: center;
}

.map-image {
  width: 100%;
  max-width: 500px;
  opacity: 0.8;
}

/* ℹ️ Info Box */
.info-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 15px;
  border-radius: 12px;
  width: 250px;
}

.info-box:hover {
  cursor: pointer;
  background: black;
}

/* 🔹 Icon */
.info-icon {
  padding-right: 10px;
}

.info-text {
  text-align: right;
}

/* 📌 Text */
.info-title {
  font-size: 14px;
}

.info-value {
  font-size: 32px;
  font-weight: bold;
}

.info-subtitle {
  font-size: 12px;
  opacity: 0.8;
}

/* ✅ Keep Your Existing Colors */
.utahcounty {
  border-left: 5px solid rgb(128, 114, 250);
}
.area {
  border-left: 5px solid rgb(205, 50, 197);
}
.largestcity {
  border-left: 5px solid #87f5e3;
}
.population {
  border-left: 5px solid salmon;
}
.poverty {
  border-left: 5px solid #879bf5;
}
.year_growth {
  border-left: 5px solid yellowgreen;
}
.male_population {
  border-left: 5px solid rgb(152, 250, 114);
}
.female_population {
  border-left: 5px solid #f5b387;
}
.houses {
  border-left: 5px solid rgb(60, 50, 205);
}

/* 📱 Responsive Styling */
@media screen and (max-width: 1200px) {
  .utah-content {
    flex-direction: column;
    align-items: center;
  }

  .utah-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1380px) {
  .utah-county-bg {
    height: 85vh;
  }
}

@media screen and (max-width: 768px) {
  .utah-county-title {
    font-size: 30px;
  }

  .utah-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .map-image {
    width: 90%;
  }
}

@media screen and (max-width: 480px) {
  .utah-county-title {
    font-size: 24px;
  }

  .info-value {
    font-size: 20px;
  }

  .map-image {
    width: 100%;
  }
}
