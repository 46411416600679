.image-src {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
  }
  
  .image-src:hover {
    transform: scale(1.1); /* Enlarges the background */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Adds shadow effect */
    border: 2px solid rgba(255, 255, 255, 0.8); /* Optional: Adds a subtle white border */
  }
  