/* Main Content Area */
.container {
   
    /* margin: 150px auto 20px; */
    /* padding: 0 20px; */
    
    
  }
  
  .content {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
    overflow: hidden;
    margin-top: 20vh;
  }
  
  .content-title {
    font-size: 2.5rem;
    color: var(--primary-color);
    margin-bottom: 10px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .content-highlight {
    font-size: 1.5rem;
    color: var(--secondary-color);
    font-weight: 600;
    margin-bottom: 20px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .content-separator {
    border: 0;
    height: 1px;
    background: linear-gradient(to right, transparent, var(--secondary-color), transparent);
    margin: 20px 0;
  }
  
  .content-description {
    font-size: 1rem;
    color: var(--text-color);
    line-height: 1.8;
    margin-bottom: 20px;
    text-align: justify;
    position: relative;
    padding-left: 30px;
  }
  
  .content-description i {
    color: var(--secondary-color);
    position: absolute;
    left: 0;
    top: 10px;
  }
  
  .content-call-to-action {
    margin-top: 30px;
  }
  
  .cta-link {
    font-size: 1rem;
    font-weight: 600;
    color: var(--primary-color);
    text-decoration: none;
    padding: 10px 20px;
    background-color: var(--secondary-color);
    border-radius: 8px;
    transition: background-color 0.3s, transform 0.3s;
    display: inline-block;
  }
  
  .cta-link:hover {
    background-color: #c48800;
    transform: scale(1.05);
  }
  



  @media (max-width: 1700px) {
    .navbar-collapse {
      background-color: white !important;
      border-radius: 0.5rem; /* Optional for a rounded look */
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional for a slight shadow */
    }
  
    .navbar-nav .nav-link {
      color: #FFAA1D !important; /* Ensure link colors match the mobile view */
    }
  
    .navbar-nav .nav-link:hover {
      background-color: #FFAA1D !important;
      color: black !important;
    }
  }
  